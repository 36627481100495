import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, Box, Section, Icon, LinkBox, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				IT Consulting & Solutions
			</title>
			<meta name={"description"} content={"Chat with a IT experts right from your phone, all day and night. No waiting or appointment needed."} />
			<meta property={"og:title"} content={"IT Consulting & Solutions"} />
			<meta property={"og:description"} content={"Chat with a IT experts right from your phone, all day and night. No waiting or appointment needed."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/63bc8b1d9faa0f00208f5318/images/favicon-32x32.png?v=2023-01-10T20:38:02.902Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/63bc8b1d9faa0f00208f5318/images/apple-touch-icon.png?v=2023-01-10T20:38:22.977Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/63bc8b1d9faa0f00208f5318/images/apple-touch-icon.png?v=2023-01-10T20:38:22.977Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/63bc8b1d9faa0f00208f5318/images/apple-touch-icon.png?v=2023-01-10T20:38:22.977Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/63bc8b1d9faa0f00208f5318/images/apple-touch-icon.png?v=2023-01-10T20:38:22.977Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/63bc8b1d9faa0f00208f5318/images/rbklogo.png?v=2023-01-10T20:38:41.284Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Image
			md-top="300px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			position="absolute"
			right="0px"
			bottom="auto"
			top="130px"
			z-index="1"
			lg-right="-200px"
			md-width="600px"
		/>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="30%"
				sm-width="50%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Text margin="0px 0px 0px 0px" color="#ffffff" height="100% border-box" font="200 50px --fontFamily-sansVerdana">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						RBK
					</Strong>
					Global
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="70%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							href="#"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
						>
							Home
						</Link>
						<Link
							href="loganalyzer"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
						>
							LogAnalyzer
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="70%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--grey"
					lg-text-align="left"
					font="--base"
					text-transform="uppercase"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					Global IT CONSULTING & SOLUTIONS
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Personalized Consulting for your business
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					Our board of fully certified specialists are available 24/7.
				</Text>
			</Box>
		</Section>
		<Section padding="24px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
				background="rgba(0, 0, 0, 0) url() 0% 0% /auto repeat scroll padding-box"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://ichef.bbci.co.uk/news/976/cpsprodpb/14DD6/production/_119526458_gettyimages-585043481.jpg"
					left="-140px"
					max-width="100%"
					object-fit="cover"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Box display="flex" margin="0px 0px 20px 0px" align-items="center" sm-width="100%">
					<Image width="24px" height="24px" src="https://uploads.quarkly.io/6123949e957019001e284458/images/icon-man.svg?v=2021-09-21T22:14:38.397Z" margin="0px 15px 0px 0px" />
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Contact a specialist in a snap
					</Text>
				</Box>
				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Take Care of Your IT
				</Text>
				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					We can help you with your IT infrastructure from build, configuration to security and processes. Our results will help your business immediately.
				</Text>
			</Box>
		</Section>
		<Section md-padding="30px 0 30px 0" padding="0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Section
				padding=" 0"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6123949e957019001e284458/images/Group%20177.png?v=2021-08-24T00:44:18.633Z) 0% 30% /cover no-repeat scroll padding-box"
				sm-padding="0px 0 0px 0"
				md-padding="0px 0 0px 0"
				height="400px"
			>
				<Override
					slot="SectionContent"
					display="grid"
					grid-gap="6px"
					grid-template-columns="repeat(4, 1fr)"
					lg-grid-gap="0px"
					md-grid-template-columns="repeat(2, 1fr)"
				/>
				<Box
					justify-content="center"
					flex-direction="column"
					background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-color="LightGray"
					align-items="center"
					display="flex"
					min-height="320px"
					empty-min-height="64px"
					empty-border-style="solid"
					padding="0px 0px 20px 0px"
				>
					<Text
						lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
						lg-margin="0px 0px 8px 0px"
						position="relative"
						z-index="1"
						color="--indigo"
						font="normal 700 49px/1.2 --fontFamily-googleInter"
						margin="0px 0px 20px 0px"
					>
						5M+
					</Text>
					<Text
						text-align="center"
						opacity="0.7"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						position="relative"
						z-index="1"
						color="--dark"
						font="--lead"
						margin="0px 0px 0px 0px"
					>
						Users potentially{" "}
						<br />
						at risk each day
					</Text>
				</Box>
				<Box
					empty-min-width="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					flex-direction="column"
					background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
					min-height="320px"
					padding="0px 0px 20px 0px"
					empty-min-height="64px"
					empty-border-width="1px"
					display="flex"
					align-items="center"
					justify-content="center"
				>
					<Text
						color="--indigo"
						font="normal 700 49px/1.2 --fontFamily-googleInter"
						margin="0px 0px 20px 0px"
						lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
						lg-margin="0px 0px 8px 0px"
						position="relative"
						z-index="1"
					>
						40k+
					</Text>
					<Text
						md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
						z-index="1"
						font="--lead"
						margin="0px 0px 0px 0px"
						text-align="center"
						opacity="0.7"
						position="relative"
						color="--dark"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					>
						New Malware Samples{" "}
						<br />
						Reported Daily
					</Text>
				</Box>
				<Box
					empty-min-height="64px"
					flex-direction="column"
					background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					sm-margin="0px 0px 0px 0px"
					empty-min-width="64px"
					empty-border-width="1px"
					justify-content="center"
					min-height="320px"
					md-margin="30px 0px 0px 0px"
					align-items="center"
					padding="0px 0px 20px 0px"
				>
					<Text
						margin="0px 0px 20px 0px"
						lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
						lg-margin="0px 0px 8px 0px"
						position="relative"
						z-index="1"
						color="--indigo"
						font="normal 700 49px/1.2 --fontFamily-googleInter"
					>
						24/7
					</Text>
					<Text
						opacity="0.7"
						md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
						z-index="1"
						margin="0px 0px 0px 0px"
						text-align="center"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						position="relative"
						color="--dark"
						font="--lead"
					>
						Our Support{" "}
					</Text>
				</Box>
				<Box
					flex-direction="column"
					md-margin="30px 0px 0px 0px"
					empty-min-height="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					empty-min-width="64px"
					display="flex"
					background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
					padding="0px 0px 20px 0px"
					sm-margin="0px 0px 0px 0px"
					min-height="320px"
					empty-border-width="1px"
					align-items="center"
					justify-content="center"
				>
					<Text
						position="relative"
						z-index="1"
						color="--indigo"
						font="normal 700 49px/1.2 --fontFamily-googleInter"
						margin="0px 0px 20px 0px"
						lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
						lg-margin="0px 0px 8px 0px"
					>
						47+
					</Text>
					<Text
						lg-width="70%"
						width="70%"
						position="relative"
						z-index="1"
						color="--dark"
						text-align="center"
						opacity="0.7"
						md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						font="--lead"
						margin="0px 0px 0px 0px"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Our Tech
					</Text>
				</Box>
			</Section>
			<Box
				border-radius="10px"
				flex-direction="column"
				width="33.333%"
				md-padding="0px 0px 0px 0px"
				sm-padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="flex-start"
				margin="0px 0px 0px 0px"
				lg-margin="0px 0px 0px 0px"
				md-width="100%"
				sm-width="100%"
				padding="16px 16px 16px 16px"
				lg-width="50%"
			>
				<Text color="--primary" lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif" margin="0px 0px 0px 0px" font="--lead">
					Highly certified IT specialists
				</Text>
				<Text
					lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					position="relative"
					z-index="1"
					color="--indigo"
					font="--headline4"
					margin="15px 0px 0px 0px"
					sm-font="700 25px/1.3 &quot;Inter&quot;, sans-serif"
				>
					IT assistance from Home
				</Text>
				<Text margin="20px 0px 10px 0px" color="--dark" font="--base" opacity=".9">
					Consult with a specialist directly from your phone, 24/7. No waiting rooms or appointments.
				</Text>
				<Image height="48px" src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z" width="48px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaHeartbeat}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Configuration review
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Manage current issues and prevent future diseases in one go.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaMedkit}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Incident Response
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Get round-the-clock assistance from world-class specialists from anywhere in the globe.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaCommentMedical}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Recommendation Reports
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						As an output of our work we provide each customer with tailored recommendations specific to findings from their environment.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaClinicMedical}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Security review
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Get peace of mind with our security reviews which assess your potential attack vectors.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="io"
						icon={IoIosWater}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						Remote Remediation
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Get high-quality remote consultancy from highly qualified specialists.
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Section lg-padding="60px 0 40px 0" padding="60px 0 40px 0" md-padding="30px 0 30px 0">
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				A New Way of Getting help
			</Text>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="30%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
					/>
				</Box>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<LinkBox
						bottom="auto"
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
					>
						<Image
							width="48px"
							height="48px"
							top="32px"
							left="auto"
							lg-height="24px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
							right="32px"
							bottom="auto"
							lg-width="24px"
						/>
					</LinkBox>
					<Text
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--headline4"
					>
						Check your cases online
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						Don't want to be sick and tired any more? For quick care for you and your children, text our specialists who are available 24/7. No wait times or appointments.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
			>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
				>
					<LinkBox
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						bottom="auto"
					>
						<Image
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							left="auto"
							lg-height="24px"
							top="32px"
							bottom="auto"
							lg-width="24px"
							width="48px"
							height="48px"
							position="static"
							right="32px"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						Manage your engagements easily
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						Our specialists are available to provide you with comprehensive remote online consultancy and professionally services at any time, anywhere.
					</Text>
				</Box>
				<Box
					md-order="-1"
					width="30%"
					display="flex"
					lg-width="50%"
					md-width="100%"
				>
					<Image
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						margin="-1px -1px -1px 0px"
						src="https://www.mckinsey.com/~/media/mckinsey/business%20functions/risk/our%20insights/cybersecurity%20tactics%20for%20the%20coronavirus%20pandemic/cybersecurity-1536x1536.jpg?mw=677&car=42:25"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="30%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://digital-skills-jobs.europa.eu/sites/default/files/styles/oe_theme_medium_no_crop/public/2021-11/AdobeStock_310133756.jpeg?itok=vjaJ_6Bv"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 17% 32px 62px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="70%"
					flex-direction="column"
					justify-content="space-between"
				>
					<LinkBox
						position="absolute"
						right="32px"
						top="32px"
						bottom="auto"
						left="auto"
						lg-right="16px"
						lg-top="49px"
					>
						<Image
							lg-width="24px"
							lg-height="24px"
							right="32px"
							top="32px"
							left="auto"
							bottom="auto"
							width="48px"
							height="48px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						Pay at affordable prices
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						We are constantly getting better and more affordable. Our technology can keep prices low because our care teams are more concentrated on you rather than paperwork or billing. Plus we got rid of copays and hidden fees.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="10px 0 60px 0"
			position="relative"
			lg-padding="60px 0 60px 0"
			sm-padding="30px 0 30px 0"
			md-padding="30px 0 30px 0"
		>
			<Override
				slot="SectionContent"
				lg-padding="0px 0px 0px 0px"
				max-width="1194px"
				flex-wrap="wrap"
				flex-direction="row"
			/>
			<Box
				lg-align-items="flex-start"
				flex-direction="row"
				justify-content="flex-start"
				margin="0px 0px 64px 0px"
				padding="0px 0px 0px 0px"
				lg-margin="0px 0px 24px 0px"
				sm-flex-wrap="wrap"
				display="flex"
				align-items="center"
				sm-margin="0px 0px 30px 0px"
				width="100%"
			>
				<Box width="40%" sm-width="100%">
					<Text color="--indigo" sm-margin="0px 0px 16px 0px" margin="0px 0px 0px 0px" font="--headline4">
						Partners
					</Text>
				</Box>
				<Box width="60%" padding="0px 10% 0px 0px" lg-padding="0px 0px 0px 0px" sm-width="100%">
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--dark">
						…RBKGlobal helps companies build the future of IT by addressing disparities of access, knowledge and affordability.
					</Text>
				</Box>
			</Box>
			<Hr
				border-style="solid"
				height="0px"
				margin="0px 0px 32px 0px"
				lg-margin="0px 0px 16px 0px"
				lg-display="none"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px 0 0 0"
			/>
			<Box
				margin="0px 0px 0px 0px"
				sm-flex-wrap="wrap"
				sm-margin="0px 0px 0px 0px"
				justify-content="center"
				flex-wrap="wrap"
				width="100%"
				display="flex"
			>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="33.333%"
					padding="32px 74px 32px 74px"
					border-width="0px 1px 0px 0px"
					sm-justify-content="center"
				>
					<Box
						box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
						justify-content="center"
						margin="0px 0px 0px 0px"
						sm-width="90%"
						width="100%"
						padding="18px 25px 18px 25px"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
					>
						<Image src="https://miro.medium.com/max/1400/0*ZLfPdBuEy3SgJscw.jpg" height="80px" />
					</Box>
				</Box>
				<Box
					border-width="0px 1px 0px 1px"
					md-border-width="0px 0px 0px 1px"
					sm-display="flex"
					sm-align-items="center"
					md-width="50%"
					sm-padding="0px 0px 0px 0px"
					sm-justify-content="center"
					width="33.333%"
					padding="32px 74px 32px 74px"
					border-style="solid"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
				>
					<Box
						sm-width="90%"
						width="100%"
						padding="18px 25px 18px 25px"
						align-items="center"
						margin="0px 0px 0px 0px"
						box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
						display="flex"
						justify-content="center"
						lg-padding="18px 18px 18px 18px"
					>
						<Image src="https://uploads.quarkly.io/6123949e957019001e284458/images/google-1-removebg-preview%201.png?v=2021-08-23T22:46:07.391Z" height="80px" />
					</Box>
				</Box>
				<Hr
					md-display="block"
					display="none"
					border-style="solid"
					height="0px"
					lg-margin="16px 0px 16px 0px"
					margin="32px 0px 32px 0px"
					width="100%"
					border-color="rgba(66, 82, 207, 0.1)"
					border-width="2px 0 0 0"
				/>
				<Box
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					md-border-width="0px 1px 0px 0px"
					sm-display="flex"
					sm-justify-content="center"
					padding="32px 74px 32px 74px"
					border-width="0px 0px 0px 1px"
					border-style="solid"
					sm-align-items="center"
					width="33.333%"
					border-color="rgba(66, 82, 207, 0.1)"
					sm-padding="0px 0px 0px 0px"
				>
					<Box
						margin="0px 0px 0px 0px"
						width="100%"
						box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						sm-width="90%"
						padding="18px 25px 18px 25px"
						display="flex"
						justify-content="center"
					>
						<Image src="https://uploads.quarkly.io/6123949e957019001e284458/images/new-Microsoft-logo-removebg-preview%201.png?v=2021-08-23T22:46:26.360Z" height="80px" />
					</Box>
				</Box>
				<Hr
					margin="32px 0px 32px 0px"
					lg-margin="16px 0px 16px 0px"
					md-display="none"
					width="100%"
					border-color="rgba(66, 82, 207, 0.1)"
					border-width="2px 0 0 0"
					border-style="solid"
					height="0px"
				/>
				<Box
					padding="32px 74px 32px 74px"
					border-style="solid"
					md-border-width="0px 0px 0px 1px"
					sm-display="flex"
					sm-justify-content="center"
					width="33.333%"
					border-width="0px 1px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-padding="0px 0px 0px 0px"
					sm-align-items="center"
				>
					<Box
						padding="18px 25px 18px 25px"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						width="100%"
						box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
						justify-content="center"
						margin="0px 0px 0px 0px"
						sm-width="90%"
					>
						<Image src="https://www.jenkins.io/images/logo-title-opengraph.png" height="80px" />
					</Box>
				</Box>
				<Hr
					height="0px"
					border-width="2px 0 0 0"
					border-style="solid"
					margin="32px 0px 32px 0px"
					lg-margin="16px 0px 16px 0px"
					md-display="block"
					display="none"
					width="100%"
					border-color="rgba(66, 82, 207, 0.1)"
				/>
				<Box
					md-width="50%"
					sm-padding="0px 0px 0px 0px"
					sm-justify-content="center"
					padding="32px 74px 32px 74px"
					border-style="solid"
					border-color="rgba(66, 82, 207, 0.1)"
					sm-display="flex"
					sm-align-items="center"
					md-border-width="0px 1px 0px 0px"
					width="33.333%"
					border-width="0px 1px 0px 1px"
					lg-padding="16px 16px 16px 16px"
				>
					<Box
						width="100%"
						padding="18px 25px 18px 25px"
						display="flex"
						justify-content="center"
						margin="0px 0px 0px 0px"
						lg-padding="18px 18px 18px 18px"
						sm-width="90%"
						box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
						align-items="center"
					>
						<Image height="80px" src="https://uploads.quarkly.io/6123949e957019001e284458/images/amazon.png?v=2021-08-23T22:48:00.645Z" />
					</Box>
				</Box>
				<Box
					sm-display="flex"
					sm-align-items="center"
					width="33.333%"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					sm-padding="0px 0px 0px 0px"
					sm-justify-content="center"
					padding="32px 74px 32px 74px"
					border-width="0px 0px 0px 1px"
					border-style="solid"
					md-width="50%"
				>
					<Box
						justify-content="center"
						margin="0px 0px 0px 0px"
						lg-padding="18px 18px 18px 18px"
						width="100%"
						box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
						padding="18px 25px 18px 25px"
						display="flex"
						align-items="center"
						sm-width="90%"
					>
						<Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Terraform_Logo.svg/1200px-Terraform_Logo.svg.png" height="80px" />
					</Box>
				</Box>
			</Box>
			<Hr
				border-style="solid"
				height="0px"
				margin="32px 0px 0px 0px"
				lg-margin="16px 0px 0px 0px"
				lg-display="none"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px 0 0 0"
			/>
		</Section>
		<Section background="--color-indigo" padding="100px 0 100px 0" md-padding="40px 0 40px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				flex-direction="column"
				md-justify-content="center"
				display="flex"
				padding="16px 16px 16px 16px"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				width="25%"
				md-align-items="center"
			>
				<Box
					empty-border-width="1px"
					empty-border-style="solid"
					width="30%"
					sm-width="50%"
					display="flex"
					align-items="center"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-color="LightGray"
				>
					<Text margin="0px 0px 0px 0px" color="#ffffff" height="100% border-box" font="200 50px --fontFamily-sansVerdana">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							RBK
						</Strong>
						Global
					</Text>
				</Box>
				<SocialMedia
					facebook="https://www.facebook.com/"
					twitter="https://twitter.com/"
					telegram="https://t.me/"
					instagram="https://www.instagram.com/"
					align-items="flex-start"
					justify-content="flex-start"
				>
					<Override
						slot="link"
						margin="0 12px 0 0px"
						hover-background="--color-indigo"
						background="--color-greyD1"
						lg-height="28px"
						lg-padding="4px 4px 4px 4px"
						lg-display="flex"
						lg-align-items="center"
						border-radius="50%"
						lg-justify-content="center"
						lg-width="28px"
						color="--light"
					/>
					<Override slot="icon" lg-font="18px sans-serif" />
				</SocialMedia>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Text color="--white" font="--lead" margin="0px 0px 20px 0px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				flex-direction="column"
				md-justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				md-width="100%"
				md-align-items="center"
				empty-border-width="1px"
			>
				<Text color="--white" font="--lead" margin="0px 0px 20px 0px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="25%"
				padding="16px 16px 16px 16px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				display="flex"
				flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Text color="--white" font="--lead" margin="0px 0px 20px 0px">
					Santa Fe
					<br />
					New Mexico
					<br />
					87505
				</Text>
			</Box>
		</Section>
		<Components.QuarklycommunityKitForm />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});